export const headline = 'Made to Create Visual Comfort & Emotion';

export const subheadline =
	'Explorează-ți identitatea și personalizează-ți tabloul!';

export const description =
	'Our mission is to evoke and amplify emotions through stunning  color-based posters, creating visual experiences that resonate deeply and inspire connection. Express your deepest emotions through art forms and colors, fostering creativity, understanding, and emotional well-being.';
export const benefits = [
	'Stunning collection of canvas art prints',
	'Natural wood frames',
	'Customized canvas posters',
];
